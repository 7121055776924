import React from "react";

const Logout = (props: { height: number; width: number; fill: string }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="-3 -3 24 24"
    width={props.width}
    height={props.height}
    fill={props.fill}
    preserveAspectRatio="xMinYMin"
  >
    <svg
      width={props.width}
      height={props.height}
      viewBox="0 0 12 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill={props.fill}
        d="M3.01167 7.02333H7.71334C7.93435 7.02333 8.14631 7.11113 8.3026 7.26741C8.45887 7.42369 8.54667 7.63565 8.54667 7.85667C8.54667 8.07768 8.45887 8.28964 8.3026 8.44592C8.14631 8.6022 7.93435 8.69 7.71334 8.69H3.01167L3.94667 9.625C4.02404 9.70243 4.08541 9.79433 4.12726 9.89547C4.16911 9.99661 4.19063 10.105 4.19059 10.2145C4.19055 10.3239 4.16895 10.4323 4.12703 10.5334C4.08511 10.6345 4.02368 10.7264 3.94626 10.8038C3.86883 10.8811 3.77692 10.9425 3.67578 10.9843C3.57464 11.0262 3.46625 11.0477 3.35679 11.0477C3.24734 11.0476 3.13896 11.026 3.03785 10.9841C2.93674 10.9422 2.84488 10.8808 2.76751 10.8033L0.410839 8.44667C0.333059 8.36944 0.271328 8.27759 0.229203 8.1764C0.187077 8.07521 0.16539 7.96669 0.16539 7.85708C0.16539 7.74748 0.187077 7.63895 0.229203 7.53777C0.271328 7.43658 0.333059 7.34473 0.410839 7.2675L2.76667 4.91083C2.84404 4.83341 2.93591 4.77198 3.03702 4.73006C3.13813 4.68813 3.2465 4.66654 3.35596 4.6665C3.46542 4.66646 3.57381 4.68798 3.67495 4.72983C3.77609 4.77168 3.868 4.83305 3.94542 4.91042C4.02285 4.98779 4.08428 5.07965 4.1262 5.18076C4.16812 5.28187 4.18972 5.39025 4.18976 5.4997C4.1898 5.60916 4.16828 5.71756 4.12642 5.81869C4.08457 5.91983 4.02321 6.01174 3.94584 6.08917L3.01167 7.02333ZM11 0.5C11.221 0.5 11.433 0.587797 11.5893 0.744078C11.7455 0.900358 11.8333 1.11232 11.8333 1.33333V14.6667C11.8333 14.8877 11.7455 15.0996 11.5893 15.2559C11.433 15.4122 11.221 15.5 11 15.5C10.779 15.5 10.567 15.4122 10.4108 15.2559C10.2545 15.0996 10.1667 14.8877 10.1667 14.6667V1.33333C10.1667 1.11232 10.2545 0.900358 10.4108 0.744078C10.567 0.587797 10.779 0.5 11 0.5Z"
      />
    </svg>
  </svg>
);

export default Logout;
