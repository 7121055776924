import NextLink from "next/link";
import React, {
  PropsWithChildren,
  AnchorHTMLAttributes,
  CSSProperties,
} from "react";

import { styled } from "@styles/theme";
import { responsiveStyling } from "@styles/responsive";

import { Text, Props as TextProps } from "./Text";

interface StyleProps extends TextProps {
  small?: boolean;
  dark?: boolean;
  scroll?: boolean;
  a?: boolean;
  removeLineHeight?: boolean;
  as?: string;
  underline?: boolean;
}

const Wrapper = styled(Text)<StyleProps>`
  font-size: ${(p) => (p.size ? `${p.size}px` : p.small ? "12px" : "14px")};
  font-family: ${(p) => p.theme.fonts.main};
  color: ${(p) =>
    p.color || p.colorWeight
      ? // @ts-ignore
        p.theme.colors[p.color || "primary"][p.colorWeight || "500"]
      : p.theme.colors.blue[600]};
  text-decoration: ${(p) => (p.underline ? "underline" : "none")};
  font-weight: ${(p) => p.weight || 600};
  line-height: ${(p) => (p.removeLineHeight ? null : "normal")};

  ${(p) =>
    responsiveStyling({
      ...p.responsiveStyles,
    })};
`;

const A = Wrapper.withComponent("a");

export type Props = {
  href: string;
  onClick?: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
  target?: AnchorHTMLAttributes<HTMLAnchorElement>["target"];
  style?: CSSProperties;
} & TextProps &
  PropsWithChildren<StyleProps>;

export default function Link({
  href,
  children,
  scroll = true,
  a,
  as,
  onClick,
  ...props
}: Props) {
  return !a ? (
    <NextLink href={href} as={as} passHref scroll={scroll}>
      <A {...props} onClick={(e) => onClick?.(e)}>
        {children}
      </A>
    </NextLink>
  ) : (
    <A href={href} rel="noreferrer" {...props} onClick={(e) => onClick?.(e)}>
      {children}
    </A>
  );
}
